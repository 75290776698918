@font-face {
  font-family: 'energy-icon';
  src:  url('../../../assets/fonts/energy-icon.eot?34zu87');
  src:  url('../../../assets/fonts/energy-icon.eot?34zu87#iefix') format('embedded-opentype'),
    url('../../../assets/fonts/energy-icon.ttf?34zu87') format('truetype'),
    url('../../../assets/fonts/energy-icon.woff?34zu87') format('woff'),
    url('../../../assets/fonts/energy-icon.svg?34zu87#energy-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="energy-icon-"], [class*=" energy-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'energy-icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.energy-icon-caret-down:before {
  content: "\e93f";
}
.energy-icon-folder:before {
  content: "\e93e";
}
.energy-icon-architect:before {
  content: "\e900";
}
.energy-icon-arrow-pointing-north:before {
  content: "\e901";
}
.energy-icon-black-envelope:before {
  content: "\e902";
}
.energy-icon-brainstorming:before {
  content: "\e903";
}
.energy-icon-calendar:before {
  content: "\e904";
}
.energy-icon-call-answer:before {
  content: "\e905";
}
.energy-icon-chronometer-outline:before {
  content: "\e906";
}
.energy-icon-cleaning-service:before {
  content: "\e907";
}
.energy-icon-electric-heating-tool-with-water-system:before {
  content: "\e908";
}
.energy-icon-energy-1:before {
  content: "\e909";
}
.energy-icon-energy:before {
  content: "\e90a";
}
.energy-icon-energy-saving:before {
  content: "\e90b";
}
.energy-icon-engineer:before {
  content: "\e90c";
}
.energy-icon-envelope-of-white-paper:before {
  content: "\e90d";
}
.energy-icon-factory:before {
  content: "\e90e";
}
.energy-icon-firewood:before {
  content: "\e90f";
}
.energy-icon-gear-1:before {
  content: "\e910";
}
.energy-icon-gear:before {
  content: "\e911";
}
.energy-icon-geothermal-energy-1:before {
  content: "\e912";
}
.energy-icon-geothermal-energy:before {
  content: "\e913";
}
.energy-icon-guarantee:before {
  content: "\e914";
}
.energy-icon-headset:before {
  content: "\e915";
}
.energy-icon-heating:before {
  content: "\e916";
}
.energy-icon-hydro-power:before {
  content: "\e917";
}
.energy-icon-idea:before {
  content: "\e918";
}
.energy-icon-industrial-robot:before {
  content: "\e919";
}
.energy-icon-internet:before {
  content: "\e91a";
}
.energy-icon-link:before {
  content: "\e91b";
}
.energy-icon-manager:before {
  content: "\e91c";
}
.energy-icon-menu:before {
  content: "\e91d";
}
.energy-icon-message:before {
  content: "\e91e";
}
.energy-icon-mill:before {
  content: "\e91f";
}
.energy-icon-money-bag:before {
  content: "\e920";
}
.energy-icon-musica-searcher:before {
  content: "\e921";
}
.energy-icon-natural-rocks-collapse:before {
  content: "\e922";
}
.energy-icon-online-shopping-cart:before {
  content: "\e923";
}
.energy-icon-order:before {
  content: "\e924";
}
.energy-icon-placeholder-filled-tool-shape-for-maps:before {
  content: "\e925";
}
.energy-icon-pump:before {
  content: "\e926";
}
.energy-icon-pumpjack:before {
  content: "\e927";
}
.energy-icon-quality:before {
  content: "\e928";
}
.energy-icon-quote:before {
  content: "\e929";
}
.energy-icon-recycled:before {
  content: "\e92a";
}
.energy-icon-renewable-energy:before {
  content: "\e92b";
}
.energy-icon-right-arrow:before {
  content: "\e92c";
}
.energy-icon-road-split:before {
  content: "\e92d";
}
.energy-icon-sea-dyke:before {
  content: "\e92e";
}
.energy-icon-shop-bag-1:before {
  content: "\e92f";
}
.energy-icon-shop-bag-2:before {
  content: "\e930";
}
.energy-icon-shop-bag-3:before {
  content: "\e931";
}
.energy-icon-shop-bag:before {
  content: "\e932";
}
.energy-icon-shopping-list:before {
  content: "\e933";
}
.energy-icon-smart-home:before {
  content: "\e934";
}
.energy-icon-smoke-detector:before {
  content: "\e935";
}
.energy-icon-star:before {
  content: "\e936";
}
.energy-icon-telephone:before {
  content: "\e937";
}
.energy-icon-thunder:before {
  content: "\e938";
}
.energy-icon-turbine:before {
  content: "\e939";
}
.energy-icon-user-image-with-black-background:before {
  content: "\e93a";
}
.energy-icon-verified-text-paper:before {
  content: "\e93b";
}
.energy-icon-windmill:before {
  content: "\e93c";
}
.energy-icon-world:before {
  content: "\e93d";
}
